<template>
  <div id="mapa-simples"></div>
</template>

<script>
import L from 'leaflet';
import PreviewMapaMixins from './PreviewMapaMixins';

export default {
  mixins: [PreviewMapaMixins],

  data() {
    return {
      mapaId: 'mapa-simples'
    };
  },

  mounted() {
    this.desenhaMapa();
  },

  computed: {
    corFeicoes() {
      return this.$store.getters.getSimbologiaConfigSimples.corFeicoes;
    },
    iconeFeicoes() {
      return this.$store.getters.getSimbologiaConfigSimples.iconeFeicoes;
    }
  },

  watch: {
    corFeicoes() {
      this.desenhaMapa();
    },
    iconeFeicoes() {
      this.desenhaMapa();
    }
  },

  methods: {
    plotaFeicoesMapa() {
      const pointToLayer = (feature, latlng) => {
        if (this.iconeFeicoes) {
          return L.marker(latlng, {
            icon: L.icon.glyph({
              iconMDI: this.iconeFeicoes,
              colorBackground: this.corFeicoes
            })
          });
        } else {
          return L.circleMarker(latlng, {
            radius: this.getMapFeatureRadius
          });
        }
      };

      this.linhasRelatorio.forEach((linha) => {
        let layer = L.geoJSON(JSON.parse(linha.geom), {
          style: () => {
            return {
              color: this.corFeicoes,
              fillColor: this.corFeicoes,
              fillOpacity: this.getMapFeatureFillOpacity,
              weight: this.getMapFeatureWeight
            };
          },
          pointToLayer
        });

        let msgPopup = this.msgPopup(linha);
        if (msgPopup) layer.bindPopup(msgPopup);

        layer.addTo(this.map);
      });
    }
  }
};
</script>

<style scoped>
#mapa-simples {
  min-height: 330px;
  max-height: 330px;
  z-index: 0;
}
</style>
